var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"flat":"","headers":_vm.headers.filter(function (header) { return header.visibleFor === _vm.role || _vm.role === 'admin'; }),"items":_vm.attachments.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('h1',{staticClass:"mb-0 text-xl-h6"},[_vm._v(_vm._s(item.task.customer.full_name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.task.customer.email))])]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.task.title))])])])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.original_name))]),_vm._v(" "),_c('br'),_c('small',[_vm._v(_vm._s(item.name))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"mb-0 text-xl-h6",on:{"click":function($event){return _vm.$router.push('/users/'+item.owner.id+'/profile')}}},[_vm._v(" "+_vm._s(item.owner.name)+" "+_vm._s(item.owner.last_name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.owner.email))])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-sm-6 my-3"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD MMM YY")))])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.getAttachmentDownloadLink(item),"download":""}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.attachments.last_page,"total-visible":6},on:{"input":_vm.paginationChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }